.help-container {
    width: 80%;
    margin: auto;
    margin-top: 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    line-height: 1.5em;
}

.help-inner-container {
    width: 80%;
    margin: auto;
    margin-top: 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}

.help-header-text {
    font-size: 1.9em;
    margin-bottom: 1em;
}

.help-header-desc-text {
    font-size: 1.3em;
    margin-bottom: 2em;
}

.help-sub-header-text-center {
    font-size: 1.8em;
    margin-bottom: 2em;
    margin-top: 1em;
    text-align: center;
    align-items: center;
    display: flex;
}

.small {
    font-size: 1.3em;
    margin-top: 1.5em;
}

.help-sub-header-text {
    font-size: 1.7em;
    /* margin-bottom: 1em; */
    margin-top: 1em;
}

.help-list {
    margin-left: -2em;
    margin-bottom: 2em;
    list-style-type: none;
    font-size: 1.2em;
}

.help-step {
    font-family: 'Impact', sans-serif; /* or your preferred font */
    font-size: 3em;
    font-weight: bold;
    /* margin-right: 1em; */
    padding-left: 0.5em; /* added padding to prevent cutting off */
    padding: 0.5em;
    border-radius: .4em;
    color: #eee;
}

.help-instruction {
    padding: 1em;
    text-align: left;
    /* transform: skewX(20deg); */
}

.help-list-item {
    display: flex;
    margin-bottom: 2em;
    align-items: center;
    text-align: left;
    border-radius: 1em;
    padding: .5em 1em;
    color: #eee;
    transition: all 0.3s ease;
    height: 5em;
    background-color: #333;
}

.help-list-item:hover {
    transform: scale(1.05);
}

.help-view-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0em;
    margin-top: -1em;
}

.help-view-switch-text {
    font-size: 1.5em;
    font-weight: 700;
}

.help-list-item-bold {
    margin-right: .1em;
    font-size: 1.3em;
}

@keyframes scroll {
    0% {
      transform: translateX(0%);
    }
  
    100% {
      transform: translateX(-30%);
    }
}
  
.help-grid-item {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2em;
    text-align: center;
    justify-content: center;
    height: 4em;
    background-color: #333;
    border-radius: 1em;
    padding: 1em;
  
    /* To ensure smooth entry on the right side */
    /* white-space: nowrap; */
}

.help-grid-item:hover {
    transform: scale(1.03);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.help-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-bottom: 2em;
}

.greenback {
    background-color: #77d970;
    color: #000;
}

.orangeback {
    background-color: #f5a623;
    color: #fff;
}

.paleback {
    background-color: #adbe32;
    color: #000;
}

.redback {
    background-color: #ff3f5c;
    color: #fff;
}

.help-grid-item:nth-child(4) {
    grid-column-start: 2;
    grid-row-start: 2;
}

.help-grid-item:nth-child(5) {
    grid-column-start: 4;
    grid-row-start: 2;
}

.help-grid-item-bold {
    font-size: 1.8em;
    margin-right: .1em;
}

.venmo-icon {
    width: 2.5em;
    height: 2.5em;
    margin-left: 1em;
}

.venmo-icon:hover {
    transform: scale(1.1);
}

.semibold {
    font-weight: 600;
}

@media (max-width: 768px) {
    .help-container {
        width: 100%;
    }

    .help-inner-container {
        width: 90%;
    }

    .help-header-text {
        font-size: 1.8em;
        margin-bottom: .6em;
    }

    .help-header-desc-text {
        font-size: 1.2em;
        margin-bottom: 1.5em;
    }

    .help-sub-header-text-center {
        font-size: 1.6em;
    }

    .help-sub-header-text {
        font-size: 1.5em;
    }

    .help-list {
        font-size: 1.2em;
    }

    .help-list-item {
        transform: skewX(0deg);
        background-color: #fff;
        padding: 1em .5em;
    }

    /* last help list item */
    .help-list-item:last-child {
        height: 8em;
    }

    .help-instruction {
        font-size: 1em;
        transform: skewX(0deg);
    }

    .help-step {
        font-size: 2em;
        margin-right: .1em;
    }

    .help-list-item-bold {
        margin-right: .1em;
        font-size: 1.2em;
    }

    .help-grid {
        display: flex;
        flex-direction: column;
        padding: 0em 1em;
        width: 90%;
    }

    .help-grid-item {
        font-size: 1.1em;
    }

    .help-grid-item-bold {
        font-size: 1.6em;
        margin-right: .1em;
    }
}

@media (prefers-color-scheme: light) {
    .help-container {
        color: #000000;
    }

    .help-header-text {
        color: #000000;
    }

    .help-header-desc-text {
        color: #000000;
    }

    .help-sub-header-text-center {
        color: #000000;
    }

    .help-sub-header-text {
        color: #000000;
    }

    .help-list-item {
        color: #000000;
        background-color: #9dbbe7;
        /* border-bottom: 2px solid #222; */
        /* border-top: 2px solid #222; */
        border-color: #222;
    }

    .help-grid-item-bold {
        color: #000000;
    }

    .help-step {
        color: #222;
    }

    .help-instruction {
        color: #222;
    }
}