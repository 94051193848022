.footer-text {
    font-size: 1.1em;
    font-weight: 500;
    z-index: 1;
    font-family: 'Montserrat', sans-serif;
    color: #eee;
}

@media (prefers-color-scheme: light) {
    .footer-text {
        color: #333;
    }
}

@media screen and (max-width: 768px) {
    .footer-text {
        display: none;
    }
}