.headstart-modal-container {
    position: fixed;
    top: 0em;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.headstart-modal {
    background-color: #111;
    border-radius: 15px;
    padding: 20px;
    width: 60%;
    position: absolute;
    top: 8em;
    /* max-width: 500px; */
    z-index: 999;
}

.headstart-content-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.headstart-content-editor-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1em;
    width: 100%;
}

.headstart-modal-content-text {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 1em;
    width: 100%;
    min-width: 250px;
}

.headstart-modal-content-textbox {
    width: 100%;
    background-color: transparent;
    border: 1px solid #444;
    border-radius: 5px;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 0.5px;
    background-color: transparent;
    resize: none;
    z-index: 1;
    padding: 10px;
    min-height: 20px;
    white-space: pre-wrap;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: #eee;
}  

.headstart-modal-content-textbox:focus {
    outline: none;
    border: 1px solid #fff;
}

@media screen and  (max-width: 600px) {
    .headstart-modal {
        width: 80%;
    }

    .headstart-modal-content-text {
        flex-direction: column;
        align-items: center;
        gap: 1em;
        width: 100%;
    }

    .headstart-content-editor-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (prefers-color-scheme: light) {
    .headstart-modal-container {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .headstart-modal {
        background-color: #fff;
    }
    .headstart-modal-content-textbox {
        color: #000;
    }
    .headstart-modal-content-textbox:focus {
        border: 1px solid #000;
    }
}