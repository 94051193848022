.view-switcher-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #333;
    border-radius: 50px;
    height: 42px;
    width: 90px;
    padding: 0px 10px;
    gap: 30px;
    overflow: hidden;
}

.view-switcher-container:hover {
    /* border: 1px solid #555; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.slider {
    position: absolute;
    top: 0em;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #555;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
}

.slider * {
    overflow: hidden;
}

.slide-left {
    transform: translateX(0);
}

.slide-right {
    transform: translateX(100%);
}

.view-switcher-box {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}


.view-switcher-side {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}

@media (max-width: 768px) {
    .view-switcher-container-special {
        position: absolute;
        top: .6em;
        right: .6em;
        height: 30px;
        width: 80px;
        padding: 5px 10px;
        gap: 25px;
        overflow: hidden;
        /* margin-bottom: 10px; */
    }

    .view-switcher-box {
        width: 18px;
        height: 18px;
    }

    .view-switcher-side {
        width: 18px;
        height: 18px;
    }
}


@media (prefers-color-scheme: light) {
    .view-switcher-container {
        background-color: #eee;
        /* border: 1px solid #aaa; */
    }

    .slider {
        background-color: #77d970;
    }

    .view-switcher-box, .view-switcher-side {
        filter: invert(1);
    }

    .view-switcher-container:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }
}
