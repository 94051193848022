.background {
    width: 100vw;
    min-height: 100vh;
    background: transparent;
    position: relative;
    overflow: hidden;
}

.background-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: #222;
    background-size: cover;
    background-position: center center;
}
  
.background-content {
    position: relative;
    z-index: 1;
}

/* #ellipse1 {
    animation: float1 1.5s ease-out 0s;
}

#ellipse2 {
    animation: float2 1s ease-out 0s;
} */

/* #ellipse1-group {
    animation: float1 1.5s ease-out 0s;
}

#ellipse2-group {
    animation: float2 1s ease-out 0s;
} */

/* @keyframes float1 {
    0% {
        transform: translate(850px, 550px);
    }
    100% {
        transform: translate(300.343650526774155px, 247.28090669500347px);
    }
}

@keyframes float2 {
    0% {
        transform: translate(0px, -200px);
    }
    100% {
        transform: translate(0px, 0.343650526774155px);
    }
} */


@media (max-width: 768px) {

}

/* light color scheme */
@media (prefers-color-scheme: light) {
    .background-svg {
        background-color: #cbdcf6;
    }
}