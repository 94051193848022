.input-buttons-container {
    position: absolute;
    bottom: 3em;
    left: auto;
    /* width: 80%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    background-color: #444;
    border-radius: 5em;
    padding: 0em;
    padding-right: 1em;
    gap: 0.5em;
}

.input-buttons-container-left {
    margin: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    padding: 0em;
}

.input-buttons-container-left button:hover::before {
    width: 85%;
}

.input-buttons-container-right {
    justify-content: flex-end;
}

.input-buttons-text {
    font-size: 1.1em;
}

@media (max-width: 768px) {
    .input-buttons-container {
        bottom: 0em;
        width: 95%;
        margin: 0em;
        flex-direction: column;
        border-radius: 2em;
        gap: .1em;
        padding: .5em;
        padding-right: 0em;
    }

    .input-buttons-container-right {
        /* width: 100%; */
        text-align: right;
    }

    .input-buttons-text {
        font-size: 1em;
        justify-content: flex-end;
    }

    .input-buttons-container-left {
        gap: .5em;
        margin: 0em;
        width: 100%;
        align-items: flex-start;
    }
}

@media (prefers-color-scheme: light) {
    .input-buttons-container {
        background-color: #9dbbe7;
    }
}