.prompt-input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
}

.prompt-input {
    font-size: 1.3em;
    padding: .5em .6em;
    /* backdrop-filter: blur(500px); */
    /* background-color: transparent; */
    background-color: transparent;
    border-radius: 13px;
    border: 1px solid #555;
    color: #ddd;
    overflow: hidden;
    resize: none;
    width: 20vw;
    height: 1.1em;
    line-height: 1.2;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap; /* add this line */
}

.prompt-input:focus {
    outline: none;
}

@media screen and (max-width: 768px) {
    .prompt-input {
        width: 70vw;
        font-size: 1.3em;
        margin-top: -10px;
    }

    .prompt-input-container {
        margin-bottom: 15px;
        align-items: flex-start;
    }
}

@media (prefers-color-scheme: light) {
    .prompt-input {
        color: #222;
        /* font-weight: 700; */
        background-color: #fff;
        border: 1px solid #999;
    }
}