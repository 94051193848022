.signup-container {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}

.signup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: .5em;
    gap: 1rem;
}

.input-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.input-field-label {
    font-size: 1.2rem;
    color: #eee;
    margin-top: .3rem;
    margin-bottom: -.3rem;
}

.login-btn:hover::before {
    width: 95%;
}

.input-field-container {
    position: relative;
}

.input-field-container input {
    padding: .65rem;
    width: 25rem;
    font-size: 1.25rem;
    border: 1px solid #666;
    border-radius: 12px;
    background-color: transparent;
    color: #eee;
}

.input-field-container label {
    font-size: 1.2rem;
    color: #ccc;
    position: absolute;
    left: 0;
    padding: 0.6em;
    align-items: flex-start;
    /* padding-left: .2em/; */
    pointer-events: none;
    text-align: left;
    transition: all 0.3s ease;
    transform-origin: left;
}

.input-fields input:focus {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 8px;
    /* background-color: transparent; */
}

.input-field-container input:focus + label,
.input-field-container input.non-empty + label {
    transform: translateY(-50%) scale(.8);
    margin: 0em;
    margin-left: .5em;
    padding: 0.1em;
    background-color: transparent;
    backdrop-filter: blur(4px);
}



.error-message {
    color: #ff3f5c;
    font-size: 1.2rem;
    margin-bottom: .2rem;
}

@media screen and (max-width: 768px) {
    .signup-container {
        margin: 1rem;
    }

    .signup-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .input-fields input {
        padding: .6rem;
        width: 20rem;
        font-size: 1.2rem;
        border: 1px solid #666;
        border-radius: 4px;
        background-color: transparent;
        color: #eee;
    }

    .input-fields input:focus {
        outline: none;
        border: 1px solid #bbb;
        border-radius: 4px;
        background-color: transparent;
        color: #eee;
    }
}

@media (prefers-color-scheme: light) {
    .input-fields input {
        color: #333;
        border-color: #aaa;
        background-color: #fff;
    }

    .input-fields input:focus {
        border-color: #aaa;
    }

    .input-field-label {
        color: #555;
    }

    .input-fields label {
        color: #888;
    }
}