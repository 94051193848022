.custom-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-bottom: 20px;
    width: 90%;
}

.custom-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

@media (max-width: 768px) {
    .custom-container {
        /* width: 90%; */
        flex-direction: column-reverse;
        align-items: flex-start;
        width: 100%;
        margin-top: -.1em;
        margin-left: 1em;
        margin-bottom: 2em;
    }

    .custom-button-container {
        margin-top: -.5em;
        margin-right: 1em;
        align-self: flex-end;
    }
}