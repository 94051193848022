.api-title {
    font-size: 2.5em;
    margin-top: 0em;
    color: #fff;
}

.api-sub-title-btn {
    margin-top: 3em;
    margin-bottom: 1.5em;
    color: #222;
    background-color: #77d970;
    border: none;
    border-radius: 5px;
    padding: .7em 1em;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
}

.api-description {
    font-size: 1.2em;
    margin-top: -.5em;
    max-width: 60%;
    color: #eee;
}

.api-info-container {
    margin-top: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.api-info {
    font-size: 1.2em;
    color: #222;
    font-weight: 500;
    background-color: #77d970;
    padding: .7em 1.2em;
    border-radius: 25px; 
}

.api-info-online {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #77d970;
    border-radius: 50px;
    margin: 0;
    padding: 0em 1em;
    height: 3.3em;
    gap: .5em;
    /* padding: .7em 1.2em; */
}

.api-endpoints-container {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1em;
}

.api-info-button {
    background-color: #77d970;
    color: #222;
    font-weight: 600;
    border: none;
    border-radius: 25px;
    padding: .7em 1em;
    font-size: 1.2em;
    cursor: pointer;
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2em;
}

.api-info-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.online-text {
    font-size: 1.2em;
    color: #222;
    font-weight: 500;
    background-color: transparent;
}

.online-circle {
    background-color: #bcffb7;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: .2em;
    /* glowing effect (shadow) all around */
    /* box-shadow: 0 0 150px #87e25a,
                0 0 100px #8de565,
                0 0 90px #94e970,
                0 0 80px #9cee7f,
                0 0 40px #a4f28c,
                0 0 20px #acf79b,
                0 0 10px #b4fba9,
                0 0 5px #bcffb7; */
    animation: glowing 4s ease-in-out infinite;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 50px #87e25a,
                    0 0 30px #8de565,
                    0 0 20px #94e970,
                    0 0 10px #9cee7f,
                    0 0 8px #a4f28c,
                    0 0 5px #acf79b,
                    0 0 2px #b4fba9,
                    0 0 1px #bcffb7;
        transform: scale(1.2);
    }
    50% {
        box-shadow: 0 0 50px #87e25a,
                    0 0 30px #999,
                    0 0 20px #999,
                    0 0 10px #999,
                    0 0 8px #999,
                    0 0 5px #999,
                    0 0 2px #999,
                    0 0 1px #999;
        transform: scale(1);
    }
    100% {
        box-shadow: 0 0 50px #87e25a,
                    0 0 30px #8de565,
                    0 0 20px #94e970,
                    0 0 10px #9cee7f,
                    0 0 8px #a4f28c,
                    0 0 5px #acf79b,
                    0 0 2px #b4fba9,
                    0 0 1px #bcffb7;
        transform: scale(1.2);
    }
}

.lock-icon {
    font-size: 1.5em;
    /* padding-top: .2em; */
}

.faded {
    opacity: .95;
}

@media (max-width: 768px) {
    .api-info-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: .1em;
        margin-top: 0em;
        margin-bottom: 1em;
    }

    .api-endpoints-container {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
        gap: 1em;
    }

    .api-title {
        font-size: 2em;
        margin-top: .1em;
        color: #fff;
    }

    .api-description {
        font-size: 1.1em;
        max-width: 80%;
        color: #eee;
    }

    .api-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (prefers-color-scheme: light) {
    .api-title {
        color: #222;
    }

    .api-description {
        color: #222;
    }

    .api-info {
        color: #222;
        background-color: #9dbbe7;
        border: none;
    }

    .api-info-button {
        background-color: #9dbbe7;
        color: #222;
    }

    .api-sub-title-btn {
        background-color: #9dbbe7;
        color: #222;
    }

    .api-info-online {
        background-color: #9dbbe7;
    }

    .api-info-button:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }

    .mobile-green {
        /* background-color: #77d970; */
        color: #222;
    }
}