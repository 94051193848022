.text-input-info-text {
    font-size: 1em;
    color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
    .text-input-info-container {
        width: 90%;
    }
}

@media (prefers-color-scheme: light) {
    .text-input-info-text {
        color: rgba(0, 0, 0, 0.5);
    }
}