.text-input-container {
    position: relative;
    width: 90%;
    margin-top: .5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
}

.text-input {
    position: relative;
    width: 100%;
    margin-top: .5em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1em;
}

.button-container .refresh-btn {
    background-color: transparent;
    border: none;
}

.input-container {
    position: relative;
    border: 1px solid #444;
    width: 100%;
    
    font-size: 1.3em;
    font-weight: 500;
    /* backdrop-filter: blur(500px); */
    /* background-color: transparent; */
    background-color: transparent;
    border-radius: 13px;
    resize: none;
    z-index: 1;
    padding: .5em .6em;
    line-height: 1;
    margin-top: .5em;
    min-height: 20px;
    white-space: pre-wrap;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}

.input-container:focus {
    border-color: #555;
}

.input-header {
    position: absolute;
    top: -2.7em;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 100%;
}

.button-container {
    display: flex;
    gap: .5em;
    align-self: flex-end;
}

.input-header__text {
    font-size: 1.1em;
    font-weight: 700;
    color: #eee;
    opacity: .8;
}

.feather {
    color: #ddd;
}

.feather:hover {
    color: #ddd;
}

.feather-rotate-cw {
    width: 1.4em;
    height: 1.4em;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

@keyframes bounceRight {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

.feather-rotate-cw:hover {
    /* color: #ddd; #f5c542 */
    animation: rotate 1s cubic-bezier(0.175, 0.82, 0.165, 1);
}

.feather-rotate-cw:active {
    color: #9a7100;
    animation: rotate .4s ease-in-out;
}

.feather-x:hover {
    color: #f54242;
}

.feather-arrow:hover {
    color: #77d970;
}

.feather-arrow-right:hover {
    color: #77d970;
    animation: bounceRight .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.text-input__textarea {
    font-size: 1.1em;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    color: #eee;
    background-color: transparent;
    border: none;
    resize: none;
    overflow: auto; /* Changed from 'hidden' to 'auto' */
}

.request-textarea {
    font-size: 1.1em;
}

.text-input__textarea:focus {
    outline: none;
}

.text-input__textarea:empty:before {
    content: attr(data-placeholder);
    color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
}
  
.input-suggestion {
    position: relative;
    display: inline-block;
}

.tab {
    font-size: .8em;
    font-weight: bold;
    color: #eee;
    background-color: #444;
    padding: 6px 10px;
    border-radius: 5px;
    margin-right: 5px;
}
  
.suggestion {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
}

.definition-text {
    font-size: 1.1em;
    color: #eee;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #444;
}

.definition-box {
    padding: 5px;
    background-color: #333;
    border-radius: 5px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1px;
}

.synonym-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.synonym-text {
    font-size: 1.2em;
    color: #eee;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #444;
}

.synonym-text:hover {
    background-color: #61965d;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes shrink {
    0% {
        width: 100%;
    }
    100% {
        width: 50%;
    }
}

.input-container.shrink {
    width: 50%;
    animation: shrink 0.5s forwards;
    transition: width 0.5s ease-in-out;
}

.input-container.slide-in {
    width: 50%;
    animation: slide-in 0.5s forwards;
}


@media (max-width: 768px) {
    .text-input {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: -2.8em;
    }

    .input-header {
        width: 100%;
        top: -2.4em;
    }

    .input-container {
        width: 100%;
        font-size: 1.3em;
        padding: 0.4em 0.5em;
        /* line-height: 1.5; */
        margin-top: 1.5em;
        /* height: 100%; */
    }

    .suggestion {
        font-size: 1.2em;
        margin-left: 2px;
        margin-top: 0px;
    }

    .input-container.shrink {
        width: 90%;
        animation: none;
    }

    .input-container.slide-in {
        width: 90%;
        animation: none;
        margin-top: 1.5em;
    }
}

@media (prefers-color-scheme: light) {
    .input-container {
        border: 1px solid #999;
        background-color: #fff;
    }

    .text-input__textarea {
        color: #222;
    }

    .input-header__text {
        color: #222;
    }

    .suggestion {
        color: rgba(0, 0, 0, 0.5);
    }

    .synonym-text {
        color: #222;
    }

    .feather {
        color: #222;
    }

    .feather-rotate-cw:hover {
        color: #222;
    }

    .feather-arrow:hover {
        color: #222;
    }

    .feather-arrow-right:hover {
        color: #222;
    }

    .definition-box {
        background-color: #eee;
    }

    .definition-text {
        background-color: #fff;
        color: #222;
    }

    .synonym-text {
        background-color: #fff;
    }

    .synonym-text:hover {
        background-color: #9bd597;
    }
}