.header-nav-container {
    margin: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}

@media screen and (max-width: 768px) {
    .header-nav-container {
        gap: 1em;
        margin: 1em;
    }
}

@media (prefers-color-scheme: light) {
    .header-nav-container {
        color: #333;
    }
}