.transparent-button {
    position: relative;
    background-color: transparent;
    border: none;
    color: #eee;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    /* overflow: hidden; */
    text-decoration: none;
}

.transparent-button-large {
    position: relative;
    background-color: transparent;
    border: none;
    color: #eee;
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
}

.transparent-button:hover {
    transform: scale(1.02);
}

.transparent-button::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0%;
    height: 2px;
    background-color: #77d970be;
    transition: width 0.3s ease-in-out;
}

.transparent-button:hover::before {
    width: 100%;
}

.full-button {
    background-color: #e9e9e9;
    border: none;
    color: #222;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: .7em 1.8em;
    border-radius: 0.3em;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.full-button:hover {
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.input-button {
    background-color: #ddd;
    border: 1px solid #aaa;
    color: #222;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: .8em 1.5em;
    border-radius: 5em;
    min-height: 3em;
    text-decoration: none;
}

.input-button:hover {
    background-color: #77d970;
    transition: background-color 0.2s ease-in-out;
}

.input-field {
    font-size: 1.2em;
    font-weight: 500;
    padding: .8em .6em;
    /* backdrop-filter: blur(500px); */
    /* background-color: transparent; */
    background-color: transparent;
    border-radius: 5em;
    border: 1px solid #555;
    color: #fff;
    overflow: hidden;
    resize: none;
    width: 20vw;
    height: 1.1em;
    line-height: 1.2;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap; /* add this line */
}

.input-field:focus {
    outline: none;
    border: 1px solid #999;
}

.input-field-with-button {
    font-size: 1.2em;
    font-weight: 500;
    padding: .8em .6em;
    padding-right: 2.5em;
    padding-left: 1em;
    background-color: transparent;
    border-radius: 5em;
    border: 1px solid #555;
    color: #fff;
    overflow: hidden;
    resize: none;
    width: 20vw;
    height: 1.1em;
    line-height: 1.2;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    position: relative;
    display: flex; /* add this line */
    align-items: center; /* add this line */
}

.input-field-with-button:focus {
    outline: none;
    border: 1px solid #999;
}

.input-field-with-button input {
    font-size: 1.2em;
    border: none; 
    background: transparent;
    color: #fff;
    width: 100%; 
}

.input-field-with-button input:focus {
    outline: none;
}

.button-inside {
    position: absolute;
    right: .2em;
    top: 50%;
    transform: translateY(-50%);
    background-color: #555;
    border-radius: 50%;
    padding: 0.7em;
    color: #fff;
    border: none;
    cursor: pointer;
}

.flat-small-btn {
    /* position: absolute; */
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;
    border: none;
    cursor: pointer;
    font-size: .9em;
    font-weight: bold;
    border-radius: 10px;
    background-color: transparent;
    color: #eee;
}

.flat-small-btn:hover {
    transform: scale(1.15);
    color: #222;
}

.green-btn {
    top: 0em;
    right: 5.9em;
}

.blue-btn {
    background-color: #ddd;
    border: none;
    color: #222;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    padding: .7em 1.8em;
    border-radius: 2em;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.blue-btn:hover {
    background-color: #77d970;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.redhover:hover {
    background-color: #ff3f5c;
    transition: background-color 0.2s ease-in-out;
}

.orangehover:hover {
    background-color: #ff9f1c;
    transition: background-color 0.2s ease-in-out;
}

@media screen and (max-width: 768px) {
    .full-button {
        padding: .8em 1.9em;
        font-size: 0.9rem;
    }

    .transparent-button {
        font-size: 1.1rem;
    }

    .flat-small-btn {
        padding: 6px 8px;
        font-size: 1em;
    }

    .input-field-with-button {
        margin-top: 1em;
        width: 80%;
    }
}

@media (prefers-color-scheme: light) {
    .transparent-button {
        color: #222;
    }

    .transparent-button::before {
        background-color: #222;
    }

    .transparent-button-large {
        color: #222;
        font-weight: 700;
        letter-spacing: -0.05em;
    }

    .full-button {
        background-color: #222;
        color: #eee;
    }

    .full-button:hover {
        background-color: #111;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }

    .input-button {
        background-color: #fff;
    }

    .input-button:hover {
        background-color: #eee;
    }

    .input-field {
        border: 1px solid #aaa;
        background-color: #fff;
        color: #222;
    }

    .input-field-with-button {
        border: 1px solid #aaa;
        background-color: #fff;
        color: #222;
    }

    .input-field-with-button input {
        color: #222;
    }

    .button-inside {
        background-color: #ccc;
        color: #222;
    }

    .flat-small-btn {
        color: #222;
    }

    .blue-btn {
        background-color: #9dbbe7;
    }

    .blue-btn:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }

    .redhover:hover {
        background-color: #ff3f5c;
    }

    .orangehover:hover {
        background-color: #ff9f1c;
    }
}